import { Suspense, lazy } from 'react'
import RenderOnViewportEntry from './hooks/useRenderViewport'
import { Route, Routes } from 'react-router-dom'

const HeroSection = lazy(() => import('./components/HeroSection'))
// const BenefitsSwiper = lazy(() => import('./components/BenefitsSwiper'))
const BecomeModel = lazy(() => import('./components/BecomeModelSwiper'))
// const AgencyMonth = lazy(() => import('./components/AgencyMonth'))
const PromotionVideo = lazy(() => import('./components/PromotionVideo'))
const TopOffersSwiper = lazy(() => import('./components/TopOffersSwiper'))
const ContactBanner = lazy(() => import('./components/ContactBanner'))
const LocationsSwiper = lazy(() => import('./components/LocationsSwiper'))
const SeconPromotionVideo = lazy(() =>
  import('./components/SeconPromotionVideo')
)
const ToursBanner = lazy(() => import('./components/ToursBanner'))
const ToursGrid = lazy(() => import('./components/ToursGrid'))
// const FooterBanner = lazy(() => import('./components/FooterBanner'))

const TopOfferAgency = lazy(() => import('./pages/TopOfferAgency'))
const LocationCountries = lazy(() => import('./pages/LocationCountries'))
const CountryAgencies = lazy(() => import('./pages/CountryAgencies'))
const Agency = lazy(() => import('./pages/Agency'))
const ToursAgency = lazy(() => import('./pages/ToursAgency'))
const MonthAgency = lazy(() => import('./pages/MonthAgency'))

function App() {
  return (
    <main>
      <Routes>
        <Route
          path='/'
          element={
            <>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <HeroSection />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <TopOffersSwiper />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <SeconPromotionVideo />
              </RenderOnViewportEntry>
              {/* <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <BenefitsSwiper />
              </RenderOnViewportEntry> */}

              {/* <RenderOnViewportEntry
                threshold={0.25}
                className='h-full bg-black'
                style={{ minHeight: '240px' }}
              >
                <AgencyMonth />
              </RenderOnViewportEntry> */}
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <BecomeModel />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <LocationsSwiper />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <ContactBanner />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <PromotionVideo />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <ToursGrid />
              </RenderOnViewportEntry>
              <RenderOnViewportEntry
                threshold={0.04}
                className='h-full bg-black'
                style={{ minHeight: '40px' }}
              >
                <ToursBanner />
              </RenderOnViewportEntry>
            </>
          }
        />
        <Route
          path='/topOffers/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <TopOfferAgency />
            </Suspense>
          }
        />
        <Route
          path='/locationCountries/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <LocationCountries />
            </Suspense>
          }
        />
        <Route
          path='/countryAgencies/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <CountryAgencies />
            </Suspense>
          }
        />
        <Route
          path='/agency/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <Agency />
            </Suspense>
          }
        />
        <Route
          path='/toursAgency/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <ToursAgency />
            </Suspense>
          }
        />
        <Route
          path='/monthAgency/:slug'
          element={
            <Suspense fallback={<p>loading...</p>}>
              <MonthAgency />
            </Suspense>
          }
        />
      </Routes>
    </main>
  )
}

export default App
