import award from '../assets/images/footer/award.avif'
import human from '../assets/images/footer/human.avif'

export default function Footer() {
  return (
    <footer className='w-full h-full pt-6 sm:pt-12 pb-2 border-t-[1px] border-zinc-900'>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 mx-auto'>
        <div className='flex items-center justify-between pb-2 sm:pb-6'>
          <div>
            <h1 className='text-3xl sm:text-6xl lg:text-8xl text-white font-bold textShadow tracking-wider'>
              TOP ESCORT
            </h1>
            <h1 className='text-white text-xs sm:text-2xl tracking-[4px] sm:tracking-[20px] sm:pl-2 textShadow'>
              elite escort services
            </h1>
          </div>
          <div className='flex items-center gap-2'>
            <img
              src={award}
              className='w-12 sm:w-24 h-12 sm:h-24 object-cover'
              alt='award_image'
            />
            <img
              src={human}
              className='w-20 sm:w-48 h-12 sm:h-20 object-cover'
              alt='award_image'
            />
          </div>
        </div>
        <article className='border-t-[1px] border-neutral-500 text-neutral-400 text-[9px] tracking-widest font-light pt-2 text-center'>
          <h1>
            Агентство <span className='font-bold'>TOP ESCORT</span> не
            предоставляет услуги интимного характера. Все представленные
            вакансии, текст и изображения являются собственностью работодателя.
            Только для девушек от 18 лет. Сопровождение для мужчин без интима
          </h1>
        </article>
      </div>
    </footer>
  )
}
