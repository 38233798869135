import React, { Suspense, useRef } from 'react'
import useFirstViewportEntry from './useFirstViewportEntry'

const RenderOnViewportEntry = ({
  children,
  threshold = 0,
  root = null,
  rootMargin = '0px 0px 0px 0px',
  ...wrapperDivProps
}) => {
  const ref = useRef()
  const entered = useFirstViewportEntry(ref, { threshold, root, rootMargin })
  return (
    <section
      {...wrapperDivProps}
      ref={ref}
    >
      {entered && <Suspense fallback={<p>loading...</p>}>{children}</Suspense>}
    </section>
  )
}

export default RenderOnViewportEntry
