const resources = {
  ru: {
    translation: {
      animatedBtn: {
        title: 'Выберите Язык',
        flagRusTitle: 'Русский',
        flagEngTitle: 'Английский',
        flagEsTitle: 'Испанский',
      },
      heroSection: {
        title: 'TOP ESCORT - ЛУЧШИЕ ЭСКОРТ АГЕНТСТВА ПО ВСЕМУ МИРУ',
        subTitle: 'ЕДИНСТВЕННАЯ ПЛАТФОРМА КОТОРАЯ ТЕБЕ НУЖНА',
        description:
          'Лучшие эскорт агентсва России, Америки, Европы, Ближнего Востока и Азии у тебя на ладони - выбери свое!',
      },
      benefits: {
        title: 'ПРЕИМУЩЕСТВА РАБОТЫ В ЭСКОРТЕ В 2025 ГОДУ',
        subTitle: 'ТЫ ПОЛУЧАЕШЬ ПРИВЕЛЕГИИ ОДЛНОЙ ИЗ БОГАТЕЙШИХ ИНДУСТРИЙ МИРА',
        videoTitle: {
          payment: 'ОПЛАТА',
          privacy: 'АНОНИМНО',
          freeTime: 'СВОБОДНОЕ ВРЕМЯ',
          safety: 'БЕЗОПАСНО',
          housing: 'ЖИЛЬЕ',
          worldWide: 'ВЕСЬ МИР',
        },
      },
      topOffers: {
        title: 'ТОПОВЫЕ АГЕНТСТВА ЭТОГО МЕСЯЦА',
        subTitle: 'НАЧНИ ЗАРАБАТЫВАТЬ С ОДНИМ ИЗ НАШИХ ТОПОВЫХ ЭСКОРТ АГЕНТСТВ',
      },
      becomeModel: {
        title: {
          title: 'СДЕЛАЙ СВОИ ПЕРВЫЕ ШАГИ В ИНДУСТРИИ ЭСКОРТА',
          subTitle: 'СТАНЬ БОГАТОЙ И УСПЕШНОЙ МОДЕЛЬЮ',
        },
        whyEscort: {
          title: 'ПОПУЛЯРНОСТЬ ИНДУСТРИИ ЭСКОРТА РАСТЕТ КАЖДЫЙ ДЕНЬ!',
          description:
            'Все чаще девушки выбирают работу в эскорте. Это не просто хорошая карьера - это красивая жизнь, полная роскоши и путешествий, эмоций и новых знакомств. Твоя молодость и красота - это ключик к успеху. Время когда нужно действовать, развиваться, добиваться поставленных целей. Ты можешь воспользоваться своей красотой и сексуальностью и получишь все, о чем ты мечтала! Эта работа открывает широкий круг общения с богатыми людьми! Ты получишь огромную пользу для своей карьеры в этой индустрии. Хорошие связи откроют вам новые возможности!',
          mobDescription:
            'Все чаще девушки выбирают работу в эскорте. Это не просто хорошая карьера - это красивая жизнь, полная роскоши и путешествий, эмоций и новых знакомств. Твоя молодость и красота - это ключик к успеху. Время когда нужно действовать, развиваться, добиваться поставленных целей',
        },
        legal: {
          title: 'АБСОЛЮТНАЯ БЕЗОПАСНОСТЬ И ЛЕГАЛЬНОСТЬ ЭСКОРТА',
          description:
            'Эскорт - это не проституция! Это профессиональное сопровождение состоятельных мужчин в поездках по всему миру, деловых встречах и светских раутах. Это не обязательно близость как многие думают - это обоюдное общение, которое может перерасти в нечто большее... Зачастую богатые мужчины оказывают щедрую финансовую поддержку девушке. Сопровождение является полностью легальной услугой. Работа в этой индустрии дает тебе очень высокий доход, уверенность в завтрашнем дне, определенные гарантии и чувство защищенности',
          mobDescription:
            'Эскорт - это не проституция! Это профессиональное сопровождение состоятельных мужчин в поездках по всему миру, деловых встречах и светских раутах. Это не обязательно близость как многие думают - это обоюдное общение, которое может перерасти в нечто большее...',
        },
        howItWorks: {
          title: 'ВМЕСТЕ С ТОБОЙ МЫ РАБОТАЕМ НА РЕЗУЛЬТАТ!',
          description:
            'Красота и молодость - твое главное преимущество. Большим плюсом будет твоя коммуникабельность и сексуальность! Грамотно поставленная речь и знание английского помогут тебе сделать успешную карьеру в сфере эскорта! Профессионалы из TOP ESCORT сотрудничают с лучшими агентствами в индустрии. Это надежный и легальный способ найти агентство на твой вкус в любой точке мира. Мы желаем тебе комфортной работы с одним из наших агентств и гарантируем анонимность и безопасность',
          mobDescription:
            'Красота и молодость - твое главное преимущество. Большим плюсом будет твоя коммуникабельность и сексуальность! Грамотно поставленная речь и знание английского помогут тебе сделать успешную карьеру в сфере эскорта!',
        },
      },
      contactBanner: {
        title: 'МЫ РАДЫ СОТРУДНИЧЕСТВУ С РЕКЛАМОДАТЕЛЯМИ',
        subTitle:
          'РАЗМЕСТИВ СВОЕ ПРЕДЛОЖЕНИЕ У НАС ВЫ ПОЛУЧАЕТЕ МАКСИМАЛЬНЫЙ РЕЗУЛЬТАТ',
      },
      world: {
        title: 'АКТУАЛЬНЫЕ ПРЕДЛОЖЕНИЯ ПО ВСЕМУ МИРУ',
        subTitle: 'ВЫБЕРИ НУЖНОЕ НАПРАВЛЕНИЕ ДЛЯ РАБОТЫ',
      },
      tours: {
        title: 'САМЫЕ ПРИБЫЛЬНЫЕ ЭСКОРТ ТУРЫ',
        subTitle: 'МЫ ПОДОБРАЛИ ДЛЯ ТЕБЯ ЛУЧШИЕ АГЕНСТВА С ТУРАМИ',
      },
      final: {
        title: 'САЙТ #1 ПО ПОИСКУ ЭСКОРТ АГЕНТСТВ',
        left: '200+ агентств на сайте',
        center: 'до 5 новых агентств ежедневно',
        right: 'обновляемся два раза в сутки',
      },
    },
  },
  en: {
    translation: {
      animatedBtn: {
        title: 'Choose language',
        flagRusTitle: 'Russian',
        flagEngTitle: 'English',
        flagEsTitle: 'Spanish',
      },
      heroSection: {
        title: 'BEST ESCORT AGENCIES WORLDWIDE',
        subTitle:
          'FIND YOUR ESCORT AGENCY IN A FEW CLICKS AND START EARNING GOOD MONEY TODAY!',
        description:
          'The best escort agencies of Russia, America, Europe, the Middle East and Asia in the palm of your hand - choose yours!',
      },
      benefits: {
        title: 'BENEFITS OF WORKING AS AN ESCORT IN 2025',
        subTitle:
          'YOU GET THE BENEFITS OF ONE OF THE RICHEST INDUSTRIES IN THE WORLD',
        videoTitle: {
          payment: 'PAYMENT',
          privacy: 'PRIVACY',
          freeTime: 'FREE TIME',
          safety: 'SAFETY',
          housing: 'HOUSING',
          worldWide: 'WORLDWIDE',
        },
      },
      topOffers: {
        title: 'THIS MONTHS HOT DEALS',
        subTitle: 'START EARNING WITH ONE OF OUR TOP ESCORT AGENCIES',
      },
      becomeModel: {
        title: {
          title: 'TAKE YOUR FIRST STEPS IN THE ESCORT INDUSTRY',
          subTitle: 'BECOME A RICH AND SUCCESSFUL MODEL',
        },
        whyEscort: {
          title: 'THE POPULARITY OF THE ESCORT INDUSTRY IS GROWING EVERY DAY!',
          description:
            'More and more often girls choose to work as escorts. It is not just a good career - it is a beautiful life, full of luxury and travel, emotions and new acquaintances. Your youth and beauty are the key to success. The time when you need to act, develop, achieve your goals. You can use your beauty and sexuality and get everything you dreamed of! This job opens a wide circle of communication with rich people! You will receive great benefits for your career in this industry. Good connections will open up new opportunities for you!',
          mobDescription:
            'More and more often girls choose to work as escorts. It is not just a good career - it is a beautiful life, full of luxury and travel, emotions and new acquaintances. Your youth and beauty are the key to success. The time when you need to act, develop, achieve your goals',
        },
        legal: {
          title: 'ABSOLUTE SAFETY AND LEGALITY OF ESCORT',
          description:
            'Escort is not prostitution! It is professional accompaniment of wealthy men on trips around the world, business meetings and social events. It is not necessarily intimacy as many people think - it is mutual communication that can develop into something more... Often, wealthy men provide generous financial support to the girl. Escort is a completely legal service. Working in this industry gives you a very high income, confidence in the future, certain guarantees and a sense of security',
          mobDescription:
            'Escort is not prostitution! It is professional accompaniment of wealthy men on trips around the world, business meetings and social events. It is not necessarily intimacy as many people think - it is mutual communication that can develop into something more...',
        },
        howItWorks: {
          title: 'TOGETHER WITH YOU WE WORK FOR THE RESULT!',
          description:
            'Beauty and youth are your main advantage. Your sociability and sexuality will be a big plus! Well-delivered speech and knowledge of English will help you make a successful career in the escort industry! Professionals from TOP ESCORT cooperate with the best agencies in the industry. This is a reliable and legal way to find an agency to your taste anywhere in the world. We wish you comfortable work with one of our agencies and guarantee anonymity and safety',
          mobDescription:
            'Beauty and youth are your main advantages. Your sociability and sexuality will be a big plus! Well-delivered speech and knowledge of English will help you make a successful career in the escort industry!',
        },
      },
      contactBanner: {
        title: 'WE ARE HAPPY TO COOPERATE WITH ADVERTISERS',
        subTitle:
          'BY PLACING YOUR OFFER WITH US YOU WILL GET THE MAXIMUM RESULT',
      },
      world: {
        title: 'LATEST OFFERS WORLDWIDE',
        subTitle: 'CHOOSE THE RIGHT DIRECTION FOR WORK',
      },
      tours: {
        title: 'THE MOST PROFITABLE ESCORT TOURS',
        subTitle: 'WE HAVE SELECTED THE BEST TOUR AGENCIES FOR YOU',
      },
      final: {
        title: 'With us you get the latest information for today in real time',
        left: '200+ agencies on the site',
        center: 'up to 5 new agencies daily',
        right: 'we update twice a day',
      },
    },
  },
  es: {
    translation: {
      animatedBtn: {
        title: 'Seleccione Idioma',
        flagRusTitle: 'Ruso',
        flagEngTitle: 'Inglés',
        flagEsTitle: 'Español',
      },
      heroSection: {
        title: 'LAS MEJORES AGENCIAS DE ESCORT DEL MUNDO',
        subTitle:
          '¡ENCUENTRA TU AGENCIA DE ESCORTS EN UNOS POCOS CLIC Y COMIENZA A GANAR BUEN DINERO HOY!',
        description:
          'Las mejores agencias de acompañantes de Rusia, América, Europa, Medio Oriente y Asia están en la palma de tu mano: ¡elige la tuya!',
      },
      benefits: {
        title: 'BENEFICIOS DE TRABAJAR COMO ESCORT EN 2025',
        subTitle:
          'OBTÉN LOS PRIVILEGIOS DE UNA DE LAS INDUSTRIAS MÁS RICAS DEL MUNDO',
        videoTitle: {
          payment: 'PAGO',
          privacy: 'PRIVACIDAD',
          freeTime: 'TIEMPO LIBRE',
          safety: 'SEGURIDAD',
          housing: 'ALOJAMIENTO',
          worldWide: 'MUNDIAL',
        },
      },
      topOffers: {
        title: 'OFERTAS CALIENTES ESTE MES',
        subTitle:
          'COMIENCE A OBTENER GANANCIAS CON UNA DE NUESTRAS AGENCIAS DE ACOMPAÑANTES PRINCIPALES',
      },
      becomeModel: {
        title: {
          title: 'DA TUS PRIMEROS PASOS EN LA INDUSTRIA ESCORT',
          subTitle: 'CONVIÉRTETE EN UN MODELO RICO Y EXITOSO',
        },
        whyEscort: {
          title: '¡LA POPULARIDAD DE LA INDUSTRIA ESCORT CRECE CADA DÍA!',
          description:
            'Cada vez más, las chicas eligen trabajar como escorts. Esto no es sólo una buena carrera, es una vida hermosa, llena de lujo y viajes, emociones y nuevos conocidos. Tu juventud y belleza son la clave del éxito. El momento en el que necesitas actuar, desarrollarte, alcanzar tus objetivos. ¡Podrás aprovechar tu belleza y sexualidad y conseguir todo lo que soñaste! ¡Este trabajo abre un amplio círculo de contactos con gente rica! Se beneficiará enormemente de su carrera en esta industria. ¡Las buenas conexiones te abrirán nuevas oportunidades!',
          mobDescription:
            'Cada vez más, las chicas eligen trabajar como escorts. Esto no es sólo una buena carrera, es una vida hermosa, llena de lujo y viajes, emociones y nuevos conocidos. Tu juventud y belleza son la clave del éxito. El momento en el que necesitas actuar, desarrollarte y alcanzar tus objetivos',
        },
        legal: {
          title: 'ABSOLUTA SEGURIDAD Y LEGALIDAD DE LA ACOMPAÑAN',
          description:
            '¡La escort no es prostitución! Se trata de un acompañamiento profesional para hombres adinerados en viajes por el mundo, reuniones de negocios y eventos sociales. Esto no es necesariamente intimidad como mucha gente piensa: es una comunicación mutua que puede convertirse en algo más... A menudo, los hombres ricos brindan un generoso apoyo financiero a una niña. El acompañamiento es un servicio completamente legal. Trabajar en esta industria te da unos ingresos muy altos, confianza en el futuro, ciertas garantías y una sensación de seguridad',
          mobDescription:
            '¡La escort no es prostitución! Se trata de un acompañamiento profesional para hombres adinerados en viajes por el mundo, reuniones de negocios y eventos sociales. Esto no es necesariamente intimidad como mucha gente piensa: es comunicación mutua que puede convertirse en algo más...',
        },
        howItWorks: {
          title: '¡JUNTO A USTED TRABAJAMOS POR RESULTADOS!',
          description:
            'La belleza y la juventud son tu principal ventaja. ¡Tu sociabilidad y sexualidad serán una gran ventaja! ¡El habla adecuada y el conocimiento del inglés te ayudarán a tener una carrera exitosa en la industria de acompañantes! Los profesionales de TOP ESCORT cooperan con las mejores agencias de la industria. Esta es una forma confiable y legal de encontrar una agencia de su elección en cualquier parte del mundo. Le deseamos un cómodo trabajo con una de nuestras agencias y le garantizamos anonimato y seguridad',
          mobDescription:
            'La belleza y la juventud son tu principal ventaja. ¡Tu sociabilidad y sexualidad serán una gran ventaja! ¡El habla adecuada y el conocimiento del inglés te ayudarán a tener una carrera exitosa en la industria de acompañantes!',
        },
      },
      contactBanner: {
        title: 'ESTAMOS ENCANTADOS DE COOPERAR CON LOS ANUNCIANTES',
        subTitle:
          'AL REALIZAR TU OFERTA CON NOSOTROS OBTENDRÁS LOS MÁXIMOS RESULTADOS',
      },
      world: {
        title: 'OFERTAS ACTUALES EN TODO EL MUNDO',
        subTitle: 'ELIJA LA DIRECCIÓN CORRECTA PARA TRABAJAR',
      },
      tours: {
        title: 'LOS TOURS DE ACOMPAÑANTES MÁS RENTABLES',
        subTitle: 'HEMOS SELECCIONADO LAS MEJORES AGENCIAS DE TOUR PARA TI',
      },
      final: {
        title:
          'Con nosotros obtienes la información más reciente de hoy en tiempo real',
        left: 'más de 200 agencias en el sitio',
        center: 'hasta 5 nuevas agencias diariamente',
        right: 'actualizamos dos veces al día',
      },
    },
  },
}

export default resources
